<template>
  <div>
    <v-card>
      <v-card-title class="pt-0 pb-0">
        <span>
          Funções
        </span>

        <v-spacer />

        <v-text-field
          v-model="filterData"
          label="Buscar por nome, setor"
          class="mt-6 mr-4"
          outlined
          dense
          @keyup.enter="findFilterResult()"
        />

        <v-btn
          color="info"
          outlined
          @click="openModal('create')"
        >
          <v-icon
            size="30"
            class="me-2"
          >
            {{ icons.mdiPlaylistPlus }}
          </v-icon>
          Novo
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="listOfFilteredItems"
        :loading="isLoading"
        loading-text="Carregando dados..."
      >
        <template
          v-slot:no-data
        >
          <span>Sem informações para exibir</span>
        </template>

        <template v-slot:item.cpf="{item}">
          {{ formattedCpfNumber(item.cpf) }}
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ dateFormat(item.created_at) }}
        </template>

        <template v-slot:item.edit="{ item }">
          <v-icon
            color="info"
            @click="openModal('details',item)"
          >
            {{ icons.mdiPencil }}
          </v-icon>
        </template>

        <template v-slot:item.delete="{ item }">
          <v-icon
            color="error"
            @click="deleteRegister(endpointDelete, item.id)"
          >
            {{ icons.mdiDelete }}
          </v-icon>
        </template>
      </v-data-table>

      <v-dialog
        v-model="showOccupationModal"
        width="700px"
        persistent
      >
        <OccupationModal
          :sectors="sectors"
          @updatedTable="updatedTable()"
          @close="showOccupationModal = false"
        >
        </OccupationModal>
      </v-dialog>

      <v-dialog
        v-model="showOccupationDetails"
        width="700px"
        persistent
      >
        <OccupationDetails
          :key="dataDetails.id"
          :sectors="sectors"
          :data="dataDetails"
          @updatedTable="updatedTable()"
          @close="showOccupationDetails = false"
        ></OccupationDetails>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiDelete, mdiFilter, mdiPencil, mdiPlaylistPlus,
} from '@mdi/js'
import OccupationDetails from './occupationDetails.vue'
import OccupationModal from './occupationModal.vue'

export default {

  components: {
    OccupationModal,
    OccupationDetails,
  },

  mixins: [formatters, messages],

  data() {
    return {
      filterData: '',
      endpointDelete: '/api/v1/records/occupation/destroy',

      headers: [
        {
          text: 'NOME', value: 'occupation.name', sortable: false, align: 'left',
        },
        {
          text: 'SETOR', value: 'sector.name', sortable: false, align: 'center',
        },
        {
          text: 'DATA DE CRIAÇÃO', value: 'created_at', sortable: false, align: 'center',
        },
        {
          text: 'ULTIMA ATUALIZAÇÃO', value: 'created_at', sortable: false, align: 'center',
        },
        {
          text: 'EDITAR', value: 'edit', sortable: false, align: 'center',
        },
        {
          text: 'EXCLUIR', value: 'delete', sortable: false, align: 'center',
        },
      ],

      sectors: [],
      itemsTable: [],
      listOfFilteredItems: [],

      dataDetails: {},

      isLoading: false,
      showOccupationDetails: false,
      showOccupationModal: false,

      icons: {
        mdiPencil,
        mdiDelete,
        mdiFilter,
        mdiPlaylistPlus,
      },
    }
  },

  watch: {
    filterData() {
      this.findFilterResult()
    },
  },

  created() {
    this.getItemsTable()
    this.getSectors()

    this.$on('updatedTable', this.updatedTable())
  },

  methods: {
    async getItemsTable() {
      this.isLoading = true

      await axiosIns.get('/api/v1/records/occupation/index').then(response => {
        this.itemsTable = response.data.data
        this.listOfFilteredItems = response.data.data
      }).catch(error => {
        this.showMessage({ title: 'Contate a equipe de desenvolvimento', text: error.message, icon: 'error' })
      }).finally(() => {
        this.isLoading = false
      })
    },

    getSectors() {
      axiosIns.get('/api/v1/records/sector/index').then(
        response => {
          this.sectors = response.data.data
        },
      ).catch(error => {
        this.showMessage({ title: 'Contate a equipe de desenvolvimento', text: error.message, icon: 'error' })
      })
    },

    findFilterResult() {
      const { itemsTable, filterData } = this
      const filterDataLower = filterData.toLowerCase()

      this.listOfFilteredItems = itemsTable.filter(({
        occupation, sector,
      }) => {
        const lowerOccupationName = occupation.name.toLowerCase()
        const lowerSectorName = sector.name.toLowerCase()

        return (
          lowerSectorName.includes(filterDataLower)
          || lowerOccupationName.includes(filterDataLower)
        )
      })
    },

    updatedTable() {
      this.getItemsTable()
    },

    openModal(type, details = {}) {
      const modal = {
        create: () => { this.showOccupationModal = true },
        details: () => {
          this.dataDetails = details
          this.showOccupationDetails = true
        },
      }

      if (type in modal) {
        modal[type]()

        return
      }

      console.error(`Invalid modal type: ${type}`)
    },
  },
}
</script>

<template>
  <v-card>
    <v-card-title class="justify-center mb-2">
      Cadastrar Função
    </v-card-title>

    <v-card-text>
      <v-form ref="form">
        <v-text-field
          v-model="occupationName"
          label="Nome da Função"
          :rules="[rules.required]"
          outlined
          dense
        />

        <v-autocomplete
          v-model="selectedSectorId"
          :items="sectors"
          item-text="name"
          item-value="id"
          label="Selecione o setor"
          :rules="[rules.required]"
          clearable
          outlined
          dense
        />
        <Separator label="Qual o nível da função" />
        <div
          class="d-flex align-center justify-center mt-2"
          style="gap: 100px;"
        >
          <v-radio-group
            v-model="radioGroup"
            row
          >
            <v-radio
              value="Líder"
              label="Líder"
            ></v-radio>
            <v-radio
              value="Gerente"
              label="Gerente"
            ></v-radio>
            <v-radio
              value="Nenhum"
              label="Nenhum"
            ></v-radio>
          </v-radio-group>
        </div>
      </v-form>

      <div class="d-flex justify-end mt-4">
        <v-btn
          color="error"
          @click="closeModal()"
        >
          Cancelar
        </v-btn>

        <v-btn
          color="info"
          class="ml-5"
          @click="sendModalData()"
        >
          <span
            v-if="!isLoading"
          >Cadastrar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import Separator from '@/components/Separator.vue'
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'

export default {

  components: {
    Separator,
  },
  mixins: [formatters, messages],

  props: {
    sectors: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selectedSectorId: '',
      occupationName: '',
      radioGroup: null,
      isLoading: false,
    }
  },

  methods: {
    async sendModalData() {
      const { occupationName, selectedSectorId } = this
      const isValid = this.$refs.form.validate()

      this.isLoading = true

      const body = {
        name: occupationName.toUpperCase(),
        sectors_id: selectedSectorId,
        is_leader: this.radioGroup === 'Líder',
        is_manager: this.radioGroup === 'Gerente',
      }

      if (!isValid) {
        this.isLoading = false
        this.showMessage({ title: 'Formulário invalido!', text: 'Verifique os campos' })

        return
      }

      await axiosIns
        .post('/api/v1/records/occupation/store', body)
        .then(() => {
          this.showMessage({
            icon: 'success',
            title: 'Informações atualizadas',
            text: 'alterado com sucesso',
          })

          this.updatedTable()
        })
        .catch(error => {
          this.showMessage({
            icon: 'error',
            title: 'Erro ao atualizar dados',
            text: error,
          })
        }).finally(() => {
          this.isLoading = false
          this.closeModal()
        })
    },
  },
}
</script>
